<template>
  <div class="PacientesAntecedentesPatologicos">
    <div class="subtitle-1">Antecedentes patológicos</div>
    <table
      style="min-width: 100%"
      v-for="(item, i) in campos"
      :key="i"
    >
      <tr>
        <td width="60%"></td>
        <td width="40%"></td>
      </tr>
      <tr v-if="item.radio">
        <td width="60%">
          <label for="">{{ item.text }}</label>
        </td>
        <td width="40%">
          <v-radio-group
            v-model="patologicos[item.radio]"
            row
            @change="_updateAntecedentesPatologicos"
          >
            <v-radio
              label="Si"
              value="si"
            />
            <v-radio
              label="No"
              value="no"
            />
          </v-radio-group>
        </td>
      </tr>
      <tr v-if="!item.radio">
        <td colspan="2">
          <v-text-field
            v-for="(input, indexinput) in item.input"
            :key="indexinput + '_' + i"
            v-model="patologicos[input.input]"
            class="mt8"
            dense
            :label="input.text"
            filled
            @blur="_updateAntecedentesPatologicos"
          />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PacientesAntecedentesPatologicos",
  props: {
    paciente: String,
  },
  mixins: [forms],
  data() {
    return {
      cargando: true,
      patologicos: {
        otros_apa: null,
        varicela_apa: "no",
        varicela_notas_apa: null,
        rubeola_apa: "no",
        rubeola_notas_apa: null,
        sarampion_apa: "no",
        sarampion_notas_apa: null,
        vih_apa: "no",
        vih_notas_apa: null,
        sifilis_apa: "no",
        sifilis_notas_apa: null,
        vph_apa: "no",
        vph_notas_apa: null,
        otras_ets_apa: null,
        hipertension_apa: "no",
        hipertension_notas_apa: null,
        diabetes_apa: "no",
        diabetes_notas_apa: null,
        cancer_apa: "no",
        cancer_notas_apa: null,
        otros_cronico_apa: null,
        hospitalizacion_apa: "no",
        hospitalizacion_motivo_apa: null,
        hospitalizacion_tiempo_apa: null,
        enfermedad_sist_apa: "no",
        enfermedad_sist_fecha_apa: null,
        tratamiento_medico_apa: "no",
        tratamiento_medico_notas_apa: null,
      },
      campos: [
        {
          text: "¿Tuviste alguna enfermedad durante tu infancia?",
          radio: false,
          input: [
            {
              text: "¿Tuviste alguna enfermedad durante tu infancia?",
              input: "otros_apa",
            },
          ],
        },
        {
          text: "Varicela",
          radio: "varicela_apa",
          input: [
            { text: "Notas sobre la varicela", input: "varicela_notas_apa" },
          ],
        },

        {
          text: "Rubéola",
          radio: "rubeola_apa",
          input: [
            { text: "Notas sobre la rubéola", input: "rubeola_notas_apa" },
          ],
        },
        {
          text: "Sarampión",
          radio: "sarampion_apa",
          input: [
            { text: "Notas sobre el sarampión", input: "sarampion_notas_apa" },
          ],
        },
        {
          text: "VIH",
          radio: "vih_apa",
          input: [{ text: "Notas sobre el VHI", input: "vih_notas_apa" }],
        },
        {
          text: "Sífilis",
          radio: "sifilis_apa",
          input: [
            { text: "Notas sobre la sífilis", input: "sifilis_notas_apa" },
          ],
        },
        {
          text: "VPH",
          radio: "vph_apa",
          input: [{ text: "Notas sobre el VPH", input: "vph_notas_apa" }],
        },
        {
          text: "Otras ETS",
          radio: false,
          input: [
            {
              text: "¿Tienes alguna ETS que no haya sido listada?",
              input: "otras_ets_apa",
            },
          ],
        },
        {
          text: "Hipertensión",
          radio: "hipertension_apa",
          input: [
            {
              text: "Notas sobre la hipertensión",
              input: "hipertension_notas_apa",
            },
          ],
        },
        {
          text: "Diabetes",
          radio: "diabetes_apa",
          input: [
            { text: "Notas sobre la diabetes", input: "diabetes_notas_apa" },
          ],
        },
        {
          text: "Cáncer",
          radio: "cancer_apa",
          input: [{ text: "Notas sobre el cáncer", input: "cancer_notas_apa" }],
        },
        //{text:"Otras enfermedades crónico degenerativas",radio:false,input:[{text:"¿Cuáles cronico degenerativas?",input:"otros_cronico_apa",},],},
        {
          text: "¿Ha sido hospitalizado?",
          radio: "hospitalizacion_apa",
          input: [
            {
              text: "Motivo de la hospitalización",
              input: "hospitalizacion_motivo_apa",
            },
            {
              text: "¿Hace cuánto tiempo fue hospitalizado?",
              input: "hospitalizacion_tiempo_apa",
            },
          ],
        },
        {
          text: "Enfermedades sitemáticas",
          radio: "enfermedad_sist_apa",
          input: [
            {
              text: "Fecha, año y otras anotaciones del diagnóstico",
              input: "enfermedad_sist_fecha_apa",
            },
          ],
        },
        {
          text: "¿Esta bajo algún tratamiento médico?",
          radio: "tratamiento_medico_apa",
          input: [
            {
              text: "¿Cuál tratamiento médico?",
              input: "tratamiento_medico_notas_apa",
            },
          ],
        },
      ],
      ruta: "antecedentes/patologicos/",
      justCreated: true,
    };
  },
  methods: {
    _getAntecedentesPatologicos() {
      this._getThings(this.ruta, "patologicos");
    },
    _updateAntecedentesPatologicos() {
      if (this.justCreated) {
        this.justCreated = false;
      } else {
        const data = { patologicos: this.patologicos };
        this._updateAll(this.ruta, data, "msg", "_wasUpdated");
      }
    },
    _wasUpdated(res) {},
  },
  created() {
    this._getAntecedentesPatologicos();
  },
  watch: {
    patologicos: {
      deep: true,
      immediate: true,
      handler: "_updateAntecedentesPatologicos",
    },
  },
};
</script>