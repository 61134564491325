<template>
  <div class="PacienteNuevoAntecedentes mt24">
    <v-card
      outlined
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title
        style="text-transform: capitalize"
        v-text="`¡Bienvenid@ ${$session.get('nombre')}!`"
      />
      <v-card-subtitle v-text="
          'Por último, es importante nos apoyes respondiendo unas preguntas sobre tus antecedentes médicos. Al conocer esto te brindaremos la atención dental adecuada durante tus tratamientos.'
        " />

      <v-stepper
        v-model="posicion"
        flat
        class="elevation-0"
      >
        <v-stepper-header
          flat
          class="elevation-0"
        >
          <v-stepper-step
            :complete="posicion > 1"
            step="1"
          >
            Alergias
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="posicion > 2"
            step="2"
          >
            Familiares
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="posicion > 3"
            step="3"
          >
            Enfermedades
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="posicion > 4"
            step="4"
          >
            Otros
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="posicion > 5"
            step="5"
          ></v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <PacientesAntecedentesAlergias />
            <ButtonStepperActions
              showNext
              @previous="posicion--"
              @next="posicion++"
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <PacientesAntecedentesHeredofamiliares />
            <ButtonStepperActions
              showNext
              showPrevious
              @previous="posicion--"
              @next="posicion++"
            />
          </v-stepper-content>
          <v-stepper-content step="3">
            <PacientesAntecedentesPatologicos />
            <ButtonStepperActions
              showNext
              showPrevious
              @previous="posicion--"
              @next="posicion++"
            />
          </v-stepper-content>
          <v-stepper-content step="4">
            <PacientesAntecedentesNoPatologicos />
            <ButtonStepperActions
              showNext
              showPrevious
              @previous="posicion--"
              @next="posicion++"
            />
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-row>
              <v-col
                cols="8"
                offset="2"
                sm="4"
                offset-sm="4"
              >
                <v-img
                  src="@/assets/logo.png"
                  style="max-width:400px;width:100%;height:auto"
                  class="mb24"
                />
                <div class="title mt24 text-center">
                  {{ $session.get("nombre") }} te agradecemos tu tiempo y tus respuestas
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <p>
                  Recuerda que la información que nos has proporcionado será
                  utilizada solo dentro de la clínica al momento de tu consulta para
                  brindarte la atención dental adecuada durante tus tratamientos. La información es complemtamente confidencial.
                </p>
              </v-col>
            </v-row>
            <ButtonStepperActions
              showSave
              showPrevious
              @previous="posicion--"
              @next="_finalizarAntecedentes"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-dialog
        v-model="cargando"
        persistent
        width="400"
      >
        <v-card>
          <v-card-text>
            <br />
            <p>
              Espera un momento, estamos guardado tu información.
            </p>
            <v-progress-linear
              indeterminate
              color="primary"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import ButtonStepperActions from "@/components/forms/buttons/ButtonStepperActions.vue";
import PacientesAntecedentesAlergias from "@/components/pacientes/antecedentes/PacientesAntecedentesAlergias.vue";
import PacientesAntecedentesHeredofamiliares from "@/components/pacientes/antecedentes/PacientesAntecedentesHeredofamiliares.vue";
import PacientesAntecedentesNoPatologicos from "@/components/pacientes/antecedentes/PacientesAntecedentesNoPatologicos.vue";
import PacientesAntecedentesPatologicos from "@/components/pacientes/antecedentes/PacientesAntecedentesPatologicos.vue";
export default {
  name: "PacienteNuevoAntecedentes",
  mixins: [standard],
  components: {
    ButtonStepperActions,
    PacientesAntecedentesAlergias,
    PacientesAntecedentesHeredofamiliares,
    PacientesAntecedentesNoPatologicos,
    PacientesAntecedentesPatologicos,
  },
  data() {
    return {
      cargando: false,
      posicion: 1,
      pasos: [
        { text: "Alergias", number: 1 },
        { text: "Familiares", number: 2 },
        { text: "Enfermedades", number: 3 },
        { text: "Otros", number: 4 },
      ],
    };
  },
  methods: {
    async _finalizarAntecedentes() {
      this.cargando = true;
      try {
        await this.$http.put(
          "perfil/completado",
          { hora: Date.now() },
          this.$headers
        );
        let self = this;
        setTimeout(function () {
          self.cargando = false;
          self.$session.set("nuevo", "no");
          self.$emit("msg", "¡Gracias por tu tiempo!");
          self.$router.push("/");
        }, 2000);
      } catch (err) {
        this.cargando = false;
      }
    },
  },
  created() {
    if (this.$session.get("nuevo") === "no") {
      this.$router.push("/");
    }
  },
  watch: {},
};
</script>