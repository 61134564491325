<template>
  <div class="PacientesAntecedentesHeredofamiliares">
    <p class="subtitle-1">En tu familia, ¿existe alguién que presente alguna de las siguientes enferdades?</p>
    <table
      style="min-width: 100%"
      v-for="(item, i) in campos"
      :key="i"
    >
      <tr>
        <td
          v-text="item.text"
          width="60%"
        />
        <td width="40%">
          <v-radio-group
            v-model="heredofamiliares[item.radio]"
            row
            @change="_updateAntecedentesHeredofamiliares"
          >
            <v-radio
              label="Si"
              value="si"
            />
            <v-radio
              label="No"
              value="no"
            />
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <v-text-field
            v-if="heredofamiliares[item.radio] === 'si'"
            v-model="heredofamiliares[item.input]"
            class="mt8"
            dense
            :label="`¿Nos pudieras decir quién de tu familia tiene ${item.text}?`"
            filled
            hint="Por ejemplo tu madre, tio, abuelo, hermano"
            @blur="_updateAntecedentesHeredofamiliares"
          />
          <v-divider v-if="i + 1 < campos.length" />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PacientesAntecedentesHeredofamiliares",
  props: {
    paciente: String,
  },
  mixins: [forms],
  data() {
    return {
      cargando: true,
      heredofamiliares: {
        diabetes_ahe: null,
        diabetes_notas_ahe: null,
        hipertension_ahe: null,
        hipertension_notas_ahe: null,
        cancer_ahe: null,
        cancer_notas_ahe: null,
        otras_ahe: null,
        otras_notas_ahe: null,
      },
      campos: [
        {
          text: "Diabetes",
          radio: "diabetes_ahe",
          input: "diabetes_notas_ahe",
        },
        {
          text: "Hipertensión",
          radio: "hipertension_ahe",
          input: "hipertension_notas_ahe",
        },
        {
          text: "Cáncer",
          radio: "cancer_ahe",
          input: "cancer_notas_ahe",
        },
        {
          text: "Otros",
          radio: "otras_ahe",
          input: "otras_notas_ahe",
        },
      ],
      ruta: "antecedentes/heredofamiliares",
      justCreated: true,
    };
  },
  methods: {
    _getAntecedentesHeredofamiliares() {
      this._getThings(this.ruta, "heredofamiliares");
    },
    _updateAntecedentesHeredofamiliares() {
      if (this.justCreated) {
        this.justCreated = false;
      } else {
        const data = { heredofamiliares: this.heredofamiliares };
        this._updateAll(this.ruta, data, "msg", "_wasUpdated");
      }
    },
    _wasUpdated(res) {},
  },
  created() {
    this._getAntecedentesHeredofamiliares();
  },
  watch: {
    heredofamiliares: {
      deep: true,
      immediate: true,
      handler: "_updateAntecedentesHeredofamiliares",
    },
  },
};
</script>