<template>
  <div class="PacienteAntecedentesAlergias">
    <p class="subtitle-1">¿Nos pudieras indicar si tienes alguna alergía?</p>
    <table
      style="min-width: 100%"
      v-for="(item, i) in campos"
      :key="i"
    >
      <tr>
        <td
          v-text="item.text"
          width="60%"
        />
        <td width="40%">
          <v-radio-group
            v-model="alergicos[item.radio]"
            row
            @change="_updateAntecedentesAlergicos"
          >
            <v-radio
              label="Si"
              value="si"
            />
            <v-radio
              label="No"
              value="no"
            />
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <v-text-field
            v-if="alergicos[item.radio] === 'si'"
            v-model="alergicos[item.input]"
            class="mt8"
            dense
            :label="`¿A cuál(es) ${item.simple} eres alérgico?`"
            filled
            @blur="_updateAntecedentesAlergicos"
          />
          <v-divider v-if="i + 1 < campos.length" />
        </td>
      </tr>
    </table>

  </div>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PacientesAntecedentesAlergias",
  mixins: [forms],
  data() {
    return {
      paciente: null,
      cargando: true,
      alergicos: {
        alimentos_aal: null,
        alimentos_notas_aal: null,
        antibioticos_aal: null,
        antibioticos_notas_aal: null,
        analgesicos_aal: null,
        analgesicos_notas_aal: null,
        otros_aal: null,
      },
      campos: [
        {
          text: "¿Eres alérgico a algún alimento?",
          radio: "alimentos_aal",
          input: "alimentos_notas_aal",
          simple: "alimento(s)",
        },
        {
          text: "¿Tienes reacciones alérgicas a algún antibiótico?",
          radio: "antibioticos_aal",
          input: "antibioticos_notas_aal",
          simple: "antibiótico(s)",
        },
        {
          text: "¿Existe algun analgésico el cual te provoque alguna reacción alérgica? (paracetamol, ibuprofeno, etc)",
          radio: "analgesicos_aal",
          input: "analgesicos_notas_aal",
          simple: "analgésico(s)",
        },
      ],
      ruta: "antecedentes/alergicos",
      justCreated: true,
    };
  },
  methods: {
    _getAntecedentesAlergicos() {
      this._getThings(this.ruta, "alergicos");
    },
    _updateAntecedentesAlergicos() {
      if (this.justCreated) {
        this.justCreated = false;
      } else {
        const data = { alergicos: this.alergicos };
        this._updateAll(this.ruta, data, "msg", "_wasUpdated");
      }
    },
    _wasUpdated(res) {},
  },
  created() {
    this._getAntecedentesAlergicos();
  },
  watch: {
    alergicos: {
      deep: true,
      immediate: true,
      handler: "_updateAntecedentesAlergicos",
    },
  },
};
</script>