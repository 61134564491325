<template>
  <div class="PacientesAntecedentesNoPatologicos">
    <div class="subtitle-1 mb24">Hábitos, costumbres y otros</div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          label="¿Cuántas veces te cepillas los dientes?"
          v-model="nopatologicos.frecuencia_cepillo_ano"
          :items="[
              '1 vez al día',
              '2 veces al día',
              '3 veces al día',
              '4 + veces al día',
              'Ninguna',
            ]"
          @blur="_updateAntecedentesNoPatologicos"
          filled
          hint="¿Con qué frecuencia te se cepillas los dientes?"
          persistent-hint
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          label="¿Ususas accesorios de higiene bucal?"
          v-model="nopatologicos.accesorios_bucales_ano"
          :items="[
              'Hilo dental',
              'Cepillo',
              'Interproximal',
              'Enjuague dental',
            ]"
          multiple
          @blur="_updateAntecedentesNoPatologicos"
          filled
          hint="¿Qué accesorios usa el paciente?"
          persistent-hint
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          label="¿Consumes golosinas?"
          v-model="nopatologicos.frecuencia_golosinas_ano"
          :items="[
              '1 vez al día',
              '2 veces al día',
              '3 + veces al día',
              'Ninguna',
            ]"
          @blur="_updateAntecedentesNoPatologicos"
          filled
          hint="¿Cuál es la frecuencia de consumo de glosinas?"
          persistent-hint
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          label="¿Cuál es tu tipo de sangre?"
          v-model="nopatologicos.grupo_sangre_ano"
          :items="[
              'O Rh negativo',
              'O Rh positivo',
              'A Rh negativo',
              'A Rh positivo',
              'B Rh negativo',
              'B Rh positivo',
              'AB Rh negativo',
              'AB Rh positivo',
            ]"
          @blur="_updateAntecedentesNoPatologicos"
          filled
        />
      </v-col>
    </v-row>
    <table
      style="min-width: 100%"
      v-for="(item, i) in campos"
      :key="i"
    >
      <tr>
        <td
          v-text="item.text"
          width="50%"
        />
        <td width="50%">
          <v-radio-group
            v-model="nopatologicos[item.radio]"
            row
            @change="_updateAntecedentesNoPatologicos"
          >
            <v-radio
              label="Si"
              value="si"
            />
            <v-radio
              label="No"
              value="no"
            />
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <v-text-field
            v-if="nopatologicos[item.radio] === 'si'"
            v-model="nopatologicos[item.input]"
            class="mt8"
            dense
            :label="item.subtext"
            filled
            @blur="_updateAntecedentesNoPatologicos"
          />
          <v-divider v-if="i + 1 < campos.length" />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PacientesAntecedentesNoPatologicos",
  props: {
    paciente: String,
  },
  mixins: [forms],
  data() {
    return {
      cargando: true,
      nopatologicos: {
        frecuencia_cepillo_ano: null,
        accesorios_bucales_ano: null,
        frecuencia_golosinas_ano: null,
        grupo_sangre_ano: null,
        acohol_ano: "no",
        alcohol_frecuencia_ano: null,
        fumador_ano: "no",
        fumador_frecuencia_ano: null,
        updated_ano: null,
      },
      campos: [
        {
          text: "¿Ingieres alcohol?",
          radio: "acohol_ano",
          input: "alcohol_frecuencia_ano",
          subtext: "¿Cuál es la frecuencia con la que consumes alcohol?",
        },
        {
          text: "Fumador",
          radio: "fumador_ano",
          input: "fumador_frecuencia_ano",
          subtext: "¿Cuál es la frecuencia con la que fumas?",
        },
      ],
      ruta: "antecedentes/nopatologicos",
      justCreated: true,
    };
  },
  methods: {
    async _getAntecedentesNoPatologicos() {
      try {
        const { status, body } = await this.$http.get(this.ruta, this.$headers);
        if (status === 200 && "nopatologicos" in body) {
          this.nopatologicos = body.nopatologicos;
          this.nopatologicos.accesorios_bucales_ano = JSON.parse(
            body.nopatologicos.accesorios_bucales_ano
          );
        } else {
          this.$emit("httpError", res);
        }
      } catch (err) {}
    },
    _updateAntecedentesNoPatologicos() {
      if (this.justCreated) {
        this.justCreated = false;
      } else {
        const predata = this.nopatologicos;
        const data = {
          nopatologicos: {
            frecuencia_cepillo_ano: predata.frecuencia_cepillo_ano,
            accesorios_bucales_ano: JSON.stringify(
              predata.accesorios_bucales_ano
            ),
            frecuencia_golosinas_ano: predata.frecuencia_golosinas_ano,
            grupo_sangre_ano: predata.grupo_sangre_ano,
            acohol_ano: predata.acohol_ano,
            alcohol_frecuencia_ano: predata.alcohol_frecuencia_ano,
            fumador_ano: predata.fumador_ano,
            fumador_frecuencia_ano: predata.fumador_frecuencia_ano,
          },
        };
        this._updateAll(this.ruta, data, "msg", "_wasUpdated");
      }
    },
    _wasUpdated(res) {},
  },
  created() {
    this._getAntecedentesNoPatologicos();
  },
  watch: {
    nopatologicos: {
      deep: true,
      immediate: true,
      handler: "_updateAntecedentesNoPatologicos",
    },
  },
};
</script>