var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PacientesAntecedentesNoPatologicos"},[_c('div',{staticClass:"subtitle-1 mb24"},[_vm._v("Hábitos, costumbres y otros")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":"¿Cuántas veces te cepillas los dientes?","items":[
            '1 vez al día',
            '2 veces al día',
            '3 veces al día',
            '4 + veces al día',
            'Ninguna',
          ],"filled":"","hint":"¿Con qué frecuencia te se cepillas los dientes?","persistent-hint":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos.frecuencia_cepillo_ano),callback:function ($$v) {_vm.$set(_vm.nopatologicos, "frecuencia_cepillo_ano", $$v)},expression:"nopatologicos.frecuencia_cepillo_ano"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":"¿Ususas accesorios de higiene bucal?","items":[
            'Hilo dental',
            'Cepillo',
            'Interproximal',
            'Enjuague dental',
          ],"multiple":"","filled":"","hint":"¿Qué accesorios usa el paciente?","persistent-hint":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos.accesorios_bucales_ano),callback:function ($$v) {_vm.$set(_vm.nopatologicos, "accesorios_bucales_ano", $$v)},expression:"nopatologicos.accesorios_bucales_ano"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":"¿Consumes golosinas?","items":[
            '1 vez al día',
            '2 veces al día',
            '3 + veces al día',
            'Ninguna',
          ],"filled":"","hint":"¿Cuál es la frecuencia de consumo de glosinas?","persistent-hint":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos.frecuencia_golosinas_ano),callback:function ($$v) {_vm.$set(_vm.nopatologicos, "frecuencia_golosinas_ano", $$v)},expression:"nopatologicos.frecuencia_golosinas_ano"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":"¿Cuál es tu tipo de sangre?","items":[
            'O Rh negativo',
            'O Rh positivo',
            'A Rh negativo',
            'A Rh positivo',
            'B Rh negativo',
            'B Rh positivo',
            'AB Rh negativo',
            'AB Rh positivo',
          ],"filled":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos.grupo_sangre_ano),callback:function ($$v) {_vm.$set(_vm.nopatologicos, "grupo_sangre_ano", $$v)},expression:"nopatologicos.grupo_sangre_ano"}})],1)],1),_vm._l((_vm.campos),function(item,i){return _c('table',{key:i,staticStyle:{"min-width":"100%"}},[_c('tr',[_c('td',{attrs:{"width":"50%"},domProps:{"textContent":_vm._s(item.text)}}),_c('td',{attrs:{"width":"50%"}},[_c('v-radio-group',{attrs:{"row":""},on:{"change":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos[item.radio]),callback:function ($$v) {_vm.$set(_vm.nopatologicos, item.radio, $$v)},expression:"nopatologicos[item.radio]"}},[_c('v-radio',{attrs:{"label":"Si","value":"si"}}),_c('v-radio',{attrs:{"label":"No","value":"no"}})],1)],1)]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[(_vm.nopatologicos[item.radio] === 'si')?_c('v-text-field',{staticClass:"mt8",attrs:{"dense":"","label":item.subtext,"filled":""},on:{"blur":_vm._updateAntecedentesNoPatologicos},model:{value:(_vm.nopatologicos[item.input]),callback:function ($$v) {_vm.$set(_vm.nopatologicos, item.input, $$v)},expression:"nopatologicos[item.input]"}}):_vm._e(),(i + 1 < _vm.campos.length)?_c('v-divider'):_vm._e()],1)])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }